import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDZD0FP8pTzgDr1OPhkm1ca32XBjOxijDE",
  authDomain: "testproject-862da.firebaseapp.com",
  projectId: "testproject-862da",
  storageBucket: "testproject-862da.appspot.com",
  messagingSenderId: "701489602268",
  appId: "1:701489602268:web:6ddf7bd2bb31ba1b1f5e19",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
